import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Input, Button, Alert } from "reactstrap";
import { baseUrl } from "..";

const ResetPassword = () => {
    const { token } = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [messageColor, setMessageColor] = useState("");

    const handleSubmit = () => {
        axios.post(`${baseUrl}/auth/reset-password`, { token, newPassword }).then(result => {
            setMessage(result.data.message)
            setMessageColor("success")
            setTimeout(() => {
                setMessage("")
                setMessageColor("")
                window.location.href = "/"
            }, 3000)
        }).catch(err => {
            console.log(err.message)
            setMessage(err.message)
            setMessageColor("danger")
            setTimeout(() => {
                setMessage("")
                setMessageColor("")
            }, 3000)
        })
    };

    return (
        <div>
            <div className="text-dark-o h1 mb-5 mt-5 text-center">Rest Password</div>
            <div className="row">
                <div className="col-xl-3 col-12 m-auto">
                    <Input className="mb-3" type="password" placeholder="Please enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    <Button onClick={handleSubmit} className="mb-3" color="success" type="submit">Reset Password</Button>
                </div>
            </div>
            {message &&
                <Alert color={messageColor}>
                    {message}
                </Alert>
            }
        </div>
    );
};

export default ResetPassword;
