import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { InputGroup, InputGroupText, Input, Button } from "reactstrap";
import { convertPayments } from "../Utils/payments";
import axios from "axios"
import { baseUrl } from ".."

const column = [
    { field: 'id', headerName: 'Transaction ID', flex: 1 },
    { field: 'paymentDate', headerName: 'Payment Date', flex: 1 },
    { field: 'bedValueSold', headerName: 'Beds Value Sold', flex: 1 },
    { field: 'revenueCollected', headerName: 'Revenue Collected', flex: 1 },
    { field: 'collectionPeriod', headerName: 'Collection Period', flex: 1 },
    { field: 'notes', headerName: 'Note', flex: 1 },
]

class Payments extends React.Component {
    constructor() {
        super()
        this.state = {
            pageSize: 10,
            currentPage: 0,
            searchInput: "",
            allTableData: []
        }
    }

    componentDidMount() {
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }

        axios.get(`${baseUrl}/payments`, headers).then(response => {
            const allPayments = convertPayments(response)
            console.log (allPayments)
            this.setState({allTableData: allPayments})
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            if (name === "pageSize") {
                this.setState({ currentPage: 0 })
            }
            this.setState({ [name]: value })
        }

        return (
            <div className="m-5">
                <div className="d-flex justify-content-between mb-3">
                    <InputGroup className="border rounded w-25">
                        <InputGroupText className="bg-transparent border-0">
                            <i class="bi bi-search"></i>
                        </InputGroupText>
                        <Input className="border-0" placeholder="Search..." onChange={onChange} value={this.state.searchInput} name="searchInput" />
                    </InputGroup>
                    {/* <Button className="bg-secondary-o text-dark-o border-0" color="dark">
                        Download
                    </Button> */}
                </div>
                <DataGrid
                    rows={this.state.allTableData}
                    columns={column}
                    pageSize={this.state.pageSize}
                    rowsPerPageOptions={[1, 2, 4]}
                    pagination
                    autoHeight
                    onPageChange={(params) => this.setState({ currentPage: params.page })}
                    onPageSizeChange={(params) => this.setState({ pageSize: params.pageSize })}
                />
            </div>
        )
    }
}

export default Payments